import { defineNuxtRouteMiddleware } from '#app';
import { useRouter } from 'vue-router';

export default defineNuxtRouteMiddleware((to, from, next) => {
    const hasToken = !!localStorage.getItem('authToken');
    if (hasToken) {
        console.log('redirect-authenticated redirect');
        const router = useRouter();
        let projectId = localStorage.getItem('projectId');
        if (projectId) {
            return router.replace('/projects/' + projectId);
        } else {
            return router.replace('/projects');
        }
    }
});